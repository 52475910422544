<template>
  <div>
    <div v-if="!showDetail">
      <div style="display: flex;justify-content: space-between;  border-bottom: 3px solid #1d74e0;">
        <div class="title">仓单列表</div>
        <div class="btn search" @click="operateSelection">注销仓单</div>
      </div>

      <div class="block">
        <el-form ref="form" :model="form" size="small">
          <el-row>
            <el-col :span="5">
              <el-form-item label="编号：" label-width="100px" prop="contractNumber">
                <el-input
                  v-model="form.contractNumber"
                  style="width: 160px"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="5">
              <el-form-item label="品名：" :label-width="formLabelWidth" prop="productName">
                <el-input v-model="form.productName" @focus="showCommodity" @input="searchCommodity" id="commodity" style="width: 160px"></el-input>

                <div class="container" v-if="showCommodityContainer">
                  <div class="head">请选择品名或输入品名的拼音</div>
                  <div class="content">
                    <div v-for="(item, index) in comContent" :key="index" class="content-item" :title="item.productName" @click="handleSelectCategoryName(item)">
                      {{ item.productName }}
                    </div>
                  </div>
                </div>

                <div class="list" v-if="showCommoditylist">
                  <ul style="list-style-type: none;cursor:pointer;">
                    <div style="border-bottom: 1px dashed #dcdcdc; color: #656565; font-size: 12px; padding-left: 10px;">
                      输入中文，进行排序
                    </div>
                    <li v-for="(item, index) in commoditySearchResult" :key="index" @click="handleSelectCategoryName(item)">
                      {{ item.productName }}
                    </li>
                  </ul>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="7">
              <el-form-item label="状态：" label-width="100px" prop="status">
                <el-select v-model="form.status" placeholder="请选择" style="width: 240px;">
                  <el-option v-for="item in options" :key="item.code" :label="item.value" :value="item.code">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item>
                <div style="display: flex">
                  <div class="btn search" @click="search">查询</div>
                  <div class="btn reset" @click="reset">重置</div>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>

      <div class="table">
        <el-table
          :data="tableData"
          :header-cell-style="{
            'text-align': 'center',
            'font-weight': 'normal',
            'font-size': '12px',
            color: '#333',
          }"
          :cell-style="{
            'text-align': 'center',
            'font-weight': 'normal',
            'font-size': '12px',
            color: '#333',
          }"
          style="width: 100%"
          size="mini"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="55" align="center"></el-table-column>
          <el-table-column
            type="index"
            :index="indexMethod"
            label="序号"
            width="55"
            align="center"
          ></el-table-column>
          <el-table-column
            v-for="(item, index) in tableHeader"
            :key="index"
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
          >
          
            <template slot-scope="scope">
              <div v-if="item.prop === 'status'">
                {{ getStatusName(scope.row.status) }}
              </div>
              <div v-else-if="item.prop === 'source'">
                {{ getSourceName(scope.row.source) }}
              </div>
              <div v-else-if="item.prop === 'contractNo'">
                <div v-if="scope.row.contractNo">
                  <div v-for="(item, index) in getArrayByComma(scope.row.contractNo)" :key="index" style="display:inline;font-size: 12px;">
                    <span v-if="index != 0">，</span>
                    <router-link :to="getRouterObj(scope.row.contractId,scope.row.contractType,scope.row)">
                      <el-link type="primary" title="点击跳转">{{
                          item
                      }}</el-link>
                    </router-link>
                  </div>
                </div>
                <div v-else style="font-size:18px;">--</div>
              </div>
              <div v-else-if="item.prop === 'transferNo'">
                <div v-if="scope.row.transferNo">
                  <div v-for="(item, index) in getArrayByComma(scope.row.transferNo)" :key="index" style="display:inline;font-size: 12px;">
                    <span v-if="index != 0">，</span>
                    <router-link :to="getRouterObjTr(item,scope.row.contractType)">
                      <el-link type="primary" title="点击跳转">{{
                          item
                      }}</el-link>
                    </router-link>
                  </div>
                </div>
                <div v-else style="font-size:18px;">--</div>
              </div>
            <div v-else-if="item.prop !== 'operate'">
                {{ scope.row[item.prop] }}
              </div>
              <div v-else>
                <el-button
                  type="text"
                  size="mini"
                  v-if="scope.row.status == '1'"
                  @click="operate(scope.row.id)"
                  > 注销仓单 </el-button
                >
              </div>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          background
          layout="total, prev, pager, next"
          :total="total"
          @current-change="handleCurrentChange"
          style="margin-top: 10px"
        ></el-pagination>
      </div>
    </div>
    <div v-if="showDetail">
      <div class="title">仓单过户单明细</div>
      <div class="c-title">过户信息</div>
      <div class="info">
        <div class="info-block">
          <div class="form-item bottom">
            <div class="label">过户单号：</div>
            <div class="value">{{ transferOrderNumber }}</div>
          </div>
          <div class="form-item">
            <div class="label">卖家：</div>
            <div class="value">{{ sellerName }}</div>
          </div>
        </div>
        <div class="info-block">
          <div class="form-item bottom">
            <div class="label">买家：</div>
            <div class="value">{{ buyerName }}</div>
          </div>
          <div class="form-item">
            <div class="label">过户仓库：</div>
            <div class="value">{{ warehouseName }}</div>
          </div>
        </div>
      </div>
      <div class="c-title">过户明细</div>
      <el-table :data="warehouseReceipts" style="width: 100%">
        <el-table-column
          prop="commodityName"
          label="品名/品牌"
          align="center"
          width="560"
        >
          <template slot-scope="scope"
            >{{ scope.row.commodityName + "/" + scope.row.productorName }}
          </template>
        </el-table-column>
        <el-table-column
          prop="billingWeight"
          label="开单重量"
          align="center"
          width="360"
        >
        </el-table-column>
        <el-table-column prop="transferNumber" label="过户件数" align="center">
        </el-table-column>
        <el-table-column prop="transferWeight" label="过户重量" align="center">
        </el-table-column>
      </el-table>

      <div class="back-btn" @click="goback">返回</div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
const http = axios.create({
  baseURL: "/",
  headers: {
    "Content-Type": "application/json",
  },
});
export default {
  name: "warehouseList",
  data() {
    return {
      allWarehouse: [],
      form: {
        productName: '',
        productid: '',
        status: '',
      },
      options: [
        {
          code:'1',
          value:'正常'
        },
        {
          code:'2',
          value:'已注销'
        },
      ],
      tableData: [],
      tableHeader: [],
      showDetail: false,

      total: 0,

      currentPage: 0,
      pageSize: 10,

      formLabelWidth: "100px",

      showCommodityContainer: false,
      showCommoditylist: false,

      commodityContent: [],
      comContent: [],
      allCommodity: [],
      commoditySearchResult: [],

      buyerName: "",
      sellerName: "",
      transferOrderNumber: "",
      warehouseName: "",
      warehouseReceipts: [],
      multipleSelection: [],
    };
  },
  created() {
    this.search();
    this.querySearchSCategoryName();
    this.querySearchSWarehouse();
    this.tableHeader = [
      {
        prop:'receiptNO',
        label:'仓单编号',
      },
      {
        prop:'productname',
        label:'品名',
      },
      {
        prop:'brandname',
        label:'品牌',
      },
      {
        prop:'stockcount',
        label:'件数',
      },
      {
        prop:'stockweight',
        label:'重量（吨）',
      },
      {
        prop:'cardno',
        label:'卡号',
      },
      {
        prop:'warehousename',
        label:'交收机构',
      },
      {
        prop:'registerDate',
        label:'注册时间',
      },
      {
        prop:'source',
        label:'仓单来源',
      },
      {
        prop:'status',
        label:'仓单状态',
      },
      {
        prop:'contractNo',
        label:'关联合同号',
      },
      {
        prop:'transferNo',
        label:'过户单号',
      },
      {
        prop:'operate',
        label:'操作',
      },
    ]
  },
  methods: {
    getArrayByComma (data) {
      if (data) {
        return data.split(',')
      }

      return []
    },
    getRouterObj (item, type, row) {
      if(row.signType){
        let active = "3"
        if(row.contractStatus === '95'||row.contractStatus === '96'||row.contractStatus === '70'||row.contractStatus === '70'||row.contractStatus === '91'||row.contractStatus === '99'){
          active = "3"
        }else if(row.contractStatus === '100'){
          active = "4"
        }else if(row.contractStatus === '201'){
          active = "5"
        }else if(row.contractStatus === '400'){
          active = "6"
        } else if(row.contractStatus === '82'||row.contractStatus === '83'){
          active = "1"
        }
        // 跳转到电子合同详情
        return{
          path: type=='2'?"/bContractManagementStatus":"/contractManagementStatus",
          query: { active: active, contractNo: item  }
        }
      }
      return {
        path: type=='2'?'/bContractManagement/bContractManagement':'/contractManagement/contractManagement',
        query: { contractNo: item }
      }
    },
    getRouterObjTr (item, type) {
      return {
        path: '/warehouseManagement/transferList',
        query: { transferNo: item }
      }
    },
    indexMethod (index) {
      index = (index + 1) + (this.currentPage) * this.pageSize
      return index
    },
        // 获取选择内容
    handleSelectionChange (val) {
      this.multipleSelection = val
    },
    getStatusName(status){
      let name = ''
      this.options.forEach(item=>{
        if(status === item.code){
          name = item.value
        }
      })
      return name
    },
        //交货仓库搜索
    querySearchSWarehouse() {
      let params = {
        process_id: '00000206',
        nextProcessNode: '006',
        execution_type: 'get',
        // addr: this.url.warehouseSearchUrl.addr, // 获取蕉农合同信息列表 接口返回的url.warehouseSearchUrl.addr 的值
        nodeInfo: {
          size: -1, // 分页页容量（从0开始，-1代表查全部）
          page: 0, // 分页页码（从0开始）
        }
      };
      this.$post("a6c611cc-ded1-4a49-8823-0c66f6ebf7d9", params).then(res => {
        if (res.succeed == 0) {
          const { data } = res;
          this.allWarehouse = data;
        }
      });
    },
    getSourceName(status){
      switch (status) {
        case '1':
          return '过户'
        case '2':
          return '注册'
        default:
          return ''
      }
    },
    handleSelectCategoryName(item) {
      this.form.productid = item.productId;
      this.form.productName = item.productName;
      setTimeout(() => {
        this.form.productName = item.productName;
      }, 0);
      this.unit = item.sweightunit;
      this.showCommodityContainer = false;
      this.showCommoditylist = false;
    },
        //品名搜索
    querySearchSCategoryName(tag) {
      let params = {
        process_id: '00000206',
        nextProcessNode: '003',
        execution_type: 'get',
        // addr: this.url.commoditySearchUrl.addr, // 获取蕉农合同信息列表 接口返回的url.commoditySearchUrl.addr 的值
        // nodeInfo: {
        //   categoryId: this.currentCategoryId, // 品种id
        //   size: -1, // 分页页容量（从0开始，-1代表查全部）
        //   page: 0, // 分页页码（从0开始）
        //   returnValueType: tag
        // }
      };
      this.$post("a6c611cc-ded1-4a49-8823-0c66f6ebf7d9", params).then(res => {
        if (res.succeed == 0) {
          const { data } = res;
          if (tag) {
            this.commodityContent = data;
          } else {
            this.allCommodity = data;
            this.comContent = this.allCommodity;
          }
        }
      });
    },
    showCommodity() {
      this.showCommodityContainer = true;
      this.showCommoditylist = false;
    },
    searchCommodity() {
      this.showCommoditylist = true;
      this.showCommodityContainer = false;
      let queryString = this.form.productName;
      this.commoditySearchResult = queryString
        ? this.allCommodity.filter(
          this.createStateFilter(queryString, "productName")
        )
        : this.allCommodity;
    },
        createStateFilter(queryString, tag) {
      if (tag == "category") {
        return state => {
          return (
            state.productName
              .toLowerCase()
              .indexOf(queryString.toLowerCase()) > -1
          );
        };
      } else if (tag == "productName") {
        return state => {
          return (
            state.productName
              .toLowerCase()
              .indexOf(queryString.toLowerCase()) > -1
          );
        };
      } else if (tag == "producer") {
        return state => {
          return (
            state.brandName
              .toLowerCase()
              .indexOf(queryString.toLowerCase()) > -1
          );
        };
      } else if (tag == "warehouse") {
        return state => {
          return (
            state.warehouseName
              .toLowerCase()
              .indexOf(queryString.toLowerCase()) > -1
          );
        };
      }
    },
    search() {
      let params = {
        process_id: "00000252",
        execution_type: "get",
        nextProcessNode: "001",
        // 传参  查询则是查询条件  可传可不传等
        nodeInfo: {
          size: this.pageSize, // 分页页容量（从0开始，-1代表查全部）
          page: this.currentPage, // 分页页码（从0开始）
          warehouseid: this.form.warehouseid,
          productid: this.form.productid,
          status: this.form.status
        },
      };
      this.$post("a6c611cc-ded1-4a49-8823-0c66f6ebf7d9", params)
        .then((res) => {

          this.tableData = res.data.content;
          this.total = res.data.total;
        })
        .catch((err) => {
        });
    },
    reset() {
      this.form = {
        transferOrderNumber: "",
        buyerName: "",
        date: [],
      };
      this.search();
    },
    operateSelection(){
      if (this.multipleSelection.length <= 0) {
        this.$message.error('请选择一项')
        return
      }
      const IDArr = []
      this.multipleSelection.forEach((item) => {
        IDArr.push(item.id)
      })
      this.operate(IDArr)
    },
    operate(id) {
      if(!Array.isArray(id)){
        id = [id]
      }
      this.$confirm('确定注销仓单吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.cancelOperate(id);
      }).catch(() => {
      })
    },
    cancelOperate(ids) {
      let params = {
        process_id: "00000252",
        execution_type: "get",
        nextProcessNode: "002",
        nodeInfo: {
          data: ids, // 过户单ID
        },
      };
      this.$post("a6c611cc-ded1-4a49-8823-0c66f6ebf7d9", params)
        .then((res) => {
          if(res.succeed === 0){
            this.$message.success('注销成功')
            this.search();
          } else {
            this.$message.error(res.errorMsg)
          }

        })
        .catch((err) => {});
    },
    goback() {
      this.showDetail = false;
    },
    handleCurrentChange(page) {
      this.currentPage = page - 1;
      this.search();
    },
    exportFile() {
      let params = {
        process_id: "00000210",
        execution_type: "get", // get 代表读请求 获取数据,  set	代表写请求 编辑等操作
        nextProcessNode: "005", // 列表接口的  button.NodeId_1字段的值
        nodeInfo: {
          size: -1, // 分页页容量（从0开始，-1代表查全部） 可不传
          page: 0, // 分页页码（从0开始）  可不传
          transferOrderNumber: this.form.transferOrderNumber, // 过户单号（可不传）
          buyerName: this.form.buyerName, // 买方名称
          beginBillingDate: this.form.date[0] || "", // 开单日期（起）
          endBillingDate: this.form.date[1] || "", // 开单日期（止）
          pageType: "Sales",
          exportType: "transferManagement",
        },
      };
      let p1 = new Promise((resolve, reject) => {
        this.$post("a6c611cc-ded1-4a49-8823-0c66f6ebf7d9", params)
          .then((res) => {
            resolve(res.data.id);
          })
          .catch((err) => {});
      });
      p1.then((res) => {
        http
          .get("/file/getFilePath?fileId=" + res)
          .then((result) => {
            const { data } = result.data;
            let url = data["common_file@path"];
            window.open(`/downfile${url}`, "_blank");
          })
          .catch((err) => {});
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  height: 35px;
  line-height: 27px;
  margin-top: 15px;
  font-size: 16px;
  font-weight: bold;
  color: #666666;
  padding-left: 30px;
}
.btn {
    background: #2d5171;
    color: #ffffff;
    font-size: 12px;
    margin-left: 10px;
    height: 30px;
    line-height: 30px;
    width: 80px;
    margin-top: 2px;
    text-align: center;
    cursor: pointer;
  }
.block {
  border: #e5e5e5 1px solid;
  padding: 10px 30px 0 30px;
  margin-top: 5px;

}
.export-btn {
  background: #2d5171;
  color: #ffffff;
  font-size: 12px;
  height: 30px;
  line-height: 30px;
  width: 80px;
  margin-top: 5px;
  text-align: center;
  cursor: pointer;
}
.table {
  margin-top: 10px;
}
.c-title {
  color: #717071;
  font-size: 14px;
  height: 35px;
  margin: 10px auto;
  line-height: 35px;
  border-bottom: 1px dashed #e3e3e3;
}
.info {
  display: flex;
  padding: 10px 0 10px 100px;
  .info-block {
    flex-basis: 400px;
    .form-item {
      display: flex;
      align-items: center;
      .label {
        width: 100px;
        text-align: right;
        font-size: 14px;
        color: #666666;
      }
      .value {
        color: #666666;
        font-size: 14px;
      }
      &.bottom {
        margin-bottom: 20px;
      }
    }
  }
}
.back-btn {
  background: #2d5171;
  color: #ffffff;
  font-size: 12px;
  margin-left: 10px;
  height: 30px;
  line-height: 30px;
  width: 80px;
  margin-top: 2px;
  text-align: center;
  cursor: pointer;
}
.container {
  position: absolute;
  z-index: 99;
  width: 480px;
  border: 1px solid rgb(47, 116, 149);
  background: #fff;
  .head {
    background: #67a1e2;
    color: #fff;
    font-size: 9pt;
    height: 24px;
    line-height: 24px;
    text-align: center;
  }
  .content {
    height: 180px;
    overflow: auto;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
  }
  .content-item {
    width: 100px;
    margin-left: 10px;
    height: 30px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    cursor: pointer;
  }
  .footer {
    display: flex;
    height: 24px;
    background: rgb(200, 227, 252);
    .common {
      width: 40px;
      &:hover {
        background: rgb(232, 244, 255);
        cursor: pointer;
      }
    }
    .key-group {
      display: flex;
      justify-content: space-between;
      width: 100%;
      .key {
        width: 100%;
        text-align: center;
        &:hover {
          background: rgb(232, 244, 255);
          cursor: pointer;
          text-align: center;
        }
      }
    }
    div {
      line-height: 24px;
    }
  }
}
.list {
  position: absolute;
  z-index: 9999;
  width: 180px;
  border: 1px solid #2f7495;
  background: #fff;
  height: 145px;
  padding: 10px 0;
  box-sizing: border-box;
  overflow: auto;
  li {
    color: #21407d;
    padding-left: 10px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    &:hover {
      background: #c8e3fc;
    }
  }
}
.table{
  ::v-deep .el-link{
    font-weight: normal;
    font-size: 12px;
  }
}
</style>
